<template>
	<div>
		<card-component title="Güncelle" icon="domain">
			<table class="table" style="width:100%">
				<tr>
					<td><b>İşlem ID: </b></td>
					<td>{{ withdraw.uuid }}</td>
				</tr>
				<tr>
					<td><b>Process ID: </b></td>
					<td>{{ withdraw.processId }}</td>
				</tr>
				<tr>
					<td><b>Kullanıcı ID: </b></td>
					<td>{{ withdraw.userId }}</td>
				</tr>
				<tr>
					<td><b>Kullanıcı Adı: </b></td>
					<td>{{ withdraw.username }}</td>
				</tr>
				<tr>
					<td><b>Ad Soyad: </b></td>
					<td>{{ withdraw.name }}</td>
				</tr>
				<tr>
					<td><b>Hesap: </b></td>
					<td>{{ withdraw.bank.type === 'CMT' ? withdraw.accountName : withdraw.bankAccount.name }}</td>
				</tr>
				<tr>
					<td><b>IBAN: </b></td>
					<td>{{ withdraw.bank.type === 'CMT' ? withdraw.iban : withdraw.bankAccount.iban }}</td>
				</tr>
				<tr>
					<td colspan="2" v-html="getLogo" class="has-text-centered"></td>
				</tr>
			</table>
			<form @submit.prevent="save">
				<b-field grouped style="width: 100%">
					<b-field label="Durum">
						<b-select
							v-model="form.status"
							expanded>
							<option
								:value="key"
								v-for="(item, key) in enums.transactionTypes"
								v-if="
									key !== 'PENDING' &&
									key !== 'WAITING' &&
									key !== withdraw.status
								">
								{{ item }}
							</option>
						</b-select>
					</b-field>
					<b-field label="Açıklama">
						<b-select v-model="form.statusReason" expanded>
							<option value="İşlem Başarılı">
								İşlem Başarılı
							</option>
							<option value="Yatırım Yok">Yatırım Yok</option>
							<option value="Zaman Aşımı">Zaman Aşımı</option>
							<option value="Mükerrer talep">
								Mükerrer talep
							</option>
						</b-select>
					</b-field>
					<b-field
						label="Ücret"
						message="Küsuratları nokta(.) ile ayırınız.">
						<b-input
							v-model="form.total"
							icon="domain"
							placeholder="Ücret"
							name="value"
							step="0.01"
							type="number" />
					</b-field>
				</b-field>
				<hr />
				<div class="is-flex is-justify-content-end is-flex-grow-1">
					<div class="control mr-2">
						<b-button
							type="is-danger is-outlined"
							@click="$emit('close')"
							>Kapat</b-button
						>
					</div>
						<b-button
							native-type="submit"
							:loading="$store.state.loading.length > 0"
							:disabled="$store.state.loading.length > 0"
							expanded
							type="is-primary"
							>Güncelle</b-button
						>
				</div>
			</form>
		</card-component>
	</div>
</template>

<script>
	import CardComponent from "@/components/CardComponent"
	import { TransactionService as Service } from "@/services"
	export default {
		name: "DepositStatusChange",
		components: { CardComponent },
		props: ["withdraw"],
		data() {
			return {
				form: {
					status: null,
					statusReason: null,
					total: 0,
					name: null,
				},
			}
		},
		mounted() {
			this.form.name = this.withdraw.name
			this.form.total = this.withdraw.total
			this.form.status = this.withdraw.status
			this.form.statusReason = this.withdraw.statusReason
			if (this.form.status === "UNSUCCESSFUL") {
				//this.$emit('close')
			}
		},
		computed: {
			getLogo() {
				let logo =
					process.env.VUE_APP_SITE_URL + "logos/banks/papara.png"
				if (this.withdraw.bank.type === "PAYFIX")
					logo =
						process.env.VUE_APP_SITE_URL + "logos/banks/payfix.png"
        if (this.withdraw.bank.type === "CMT")
          logo = process.env.VUE_APP_SITE_URL + "logos/banks/cmt.png"
        if (this.withdraw.bank.type === "TETHER")
          logo = process.env.VUE_APP_SITE_URL + "logos/banks/tether.svg"
        if (this.withdraw.bank.type === "IQ_MONEY")
          logo = process.env.VUE_APP_SITE_URL + "logos/banks/iqmoney2.png"
				if (this.withdraw.bank.type === "ISYERIMPOS")
					logo =
						process.env.VUE_APP_SITE_URL + "logos/banks/isyerimpos.svg"
				if (this.withdraw.bank.type === "NAYS")
					logo =
						process.env.VUE_APP_SITE_URL + "logos/banks/nays.svg"
				if (
					this.withdraw.bank.type === "BANK_TRANSFER" ||
					this.withdraw.bank.type === "FOREX"
				) {
					logo = this.withdraw.bankAccount?.bankList?.logo
				}
				return `<img width="150" style="max-width:150px" src="${logo}" />`
			},
		},
		methods: {
			save() {
				Service.statusChange({ uuid: this.withdraw.uuid, ...this.form })
					.then((res) => {
						this.$buefy.toast.open({
							message: res.data.message,
							type: res.data.status ? "is-success" : "is-danger",
						})
						this.$emit("close")
					})
					.catch((err) =>
						this.$buefy.toast.open({
							message: err.message,
							position: "is-top-right",
							type: "is-danger",
						})
					)
			},
			reset() {
				this.form = mapValues(this.form, (item) => {
					if (item && typeof item === "object") {
						return []
					}
					return null
				})
			},
		},
		watch: {
			"form.status": function () {
				if (this.form.status === "SUCCESSFUL")
					this.form.statusReason = "İşlem Başarılı"
				else if (this.form.status === "UNSUCCESSFUL")
					this.form.statusReason = "Yatırım Yok"
				else this.form.statusReason = null
			},
		},
	}
</script>

<style>
	.vm--modal {
		height: auto !important;
	}
</style>
