/* Styles */
import "@/scss/main.scss"

/* Core */
import Vue from "vue"
import Buefy from "buefy"

/* Router & Store */
import router from "./router"
import store from "./store"

/* Vue. Main component */
import App from "./App.vue"

/* Enums */
import enums from "@/utils/enums"
import FinanceTable from "@/components/FinanceTable"
import TableFilter from "@/components/TableFilter"
import VModal from "vue-js-modal"
import DatePicker from "vue2-datepicker"
import "vue2-datepicker/index.css"

import VueI18n from 'vue-i18n'
import translateEn from '@/lang/en.json'
import translateTr from '@/lang/tr.json'

import VueTheMask from "vue-the-mask"
import {
	add,
	addHours,
	endOfDay,
	endOfMonth,
	endOfWeek,
	endOfYear,
	startOfDay,
	startOfMonth,
	startOfWeek,
	startOfYear,
} from "date-fns"

import { format as formatTz, utcToZonedTime } from "date-fns-tz"

/* Default title tag */
const defaultDocumentTitle = process.env.VUE_APP_NAME

Vue.component("finance-datepicker", DatePicker)
Vue.component("finance-table", FinanceTable)
Vue.component("table-filter", TableFilter)

Vue.use(VueTheMask)
Vue.use(VModal, {
	dynamicDefaults: {
		adaptive: true,
		scrollable: true,
	},
})
Vue.use(Buefy)
Vue.use(VueI18n)
const i18n = new VueI18n({
	locale: store.state.lang || 'tr', // set locale
	messages: {
		'en': translateEn,
		'tr': translateTr
	}
})
import VueSocketIO from "vue-socket.io"
import SocketIO from "socket.io-client"
const socketConnect = () => {
	if (store.state.isLoggedIn) {
		const socketOptions = {
			transports: ["websocket"],
			query: {
				u: store.state.user.username,
				i: store.state.user.uuid,
				r: store.state.user.role,
				s: store.state.user.site || "",
				g: store.state.user.group,
				t: store.state.user.accessToken,
			},
		}
		Vue.use(
			new VueSocketIO({
				debug: false,
				connection: SocketIO(
					process.env.VUE_APP_SOCKET_URL,
					socketOptions
				),
			})
		)
	}
}
Vue.mixin({
	data() {
		return {
			datepicker: {
				shortcuts: [
					{
						text: "Bugün",
						onClick: () => [new Date(), new Date()],
					},
					{
						text: "Dün",
						onClick: () => {
							const date = new Date()
							date.setTime(date.getTime() - 3600 * 1000 * 24)
							return [date, date]
						},
					},
					{
						text: "Bu hafta",
						onClick: () => [
							startOfWeek(new Date(), { weekStartsOn: 1 }),
							endOfWeek(new Date(), { weekStartsOn: 1 }),
						],
					},
					{
						text: "Geçen Hafta",
						onClick: () => [
							add(startOfWeek(new Date(), { weekStartsOn: 1 }), {
								weeks: -1,
							}),
							add(endOfWeek(new Date(), { weekStartsOn: 1 }), {
								weeks: -1,
							}),
						],
					},
					{
						text: "Bu Ay",
						onClick: () => [
							startOfMonth(new Date()),
							endOfMonth(new Date()),
						],
					},
					{
						text: "Geçen Ay",
						onClick: () => [
							add(startOfMonth(new Date()), { months: -1 }),
							add(endOfMonth(new Date()), { months: -1 }),
						],
					},
					{
						text: "Bu Yıl",
						onClick: () => [
							startOfYear(new Date()),
							endOfYear(new Date()),
						],
					},
					{
						text: "Geçen Yıl",
						onClick: () => [
							add(startOfYear(new Date()), { years: -1 }),
							add(endOfYear(new Date()), { years: -1 }),
						],
					},
					{
						text: "Son 30 Gün",
						onClick: () => [
							add(new Date(), { days: -30 }),
							new Date(),
						],
					},
				],
				datePickerLang: {
					formatLocale: {
						firstDayOfWeek: 1,
					},
					monthBeforeYear: true,
				},
			},
			enums,
			dateFormats: {
				normalDate: "dd.MM.yyyy",
				normalDateTime: "dd.MM.yyyy HH:mm",
				normalDateTimeS: "dd.MM.yyyy HH:mm:ss",
				dbDate: "yyyy-MM-dd",
				dbDateTime: "yyyy-MM-dd HH:mm:ss",
				dbTime: "HH:mm",
				year: "yyyy",
			},
			transactionStatusTypes: {
				PENDING: {
					color: "warning",
					text: "Beklemede",
				},
				SUCCESSFUL: {
					color: "success",
					text: "Onaylandı",
				},
				UNSUCCESSFUL: {
					color: "danger",
					text: "Başarısız",
				},
				WAITING: {
					color: "info",
					text: "Kontrol B.",
				},
			},
			siteName: process.env.VUE_APP_NAME,
			site: process.env.VUE_APP_NAME.split(".")[0],
			bankTypes: [
				"PAPARA",
				"PAPARA_TO_IBAN",
				"BANK_TRANSFER",
				"CARD",
				"PAYFIX"
			],
			depositTypes: [
				"PAPARA",
				"BANK_TRANSFER",
				"PAYFIX",
				"ISYERIMPOS",
				"PAYTURCA",
				"PAPARA_TO_IBAN",
				"PAYMAGO",
				"PAYCO",
				"NEWPAYTR",
				"PAYPOLE",
				"TETHER",
				"MEDUSA",
				"IQ_MONEY",
			],
			currencies: {
				USD: "$",
				TRY: "₺",
				EUR: "€",
				GBP: "£",
				USDT: "USDT",
			},
			theme: process.env.VUE_APP_THEME,
		}
	},
	methods: {
		getTodayDate(val = null) {
			const today = new Date(val);

			// Tarihi yyyy-mm-dd formatında oluştur
			const year = today.getFullYear();
			const month = String(today.getMonth() + 1).padStart(2, '0'); // Ay sıfır ile başlamalıdır
			const day = String(today.getDate()).padStart(2, '0'); // Gün sıfır ile başlamalıdır

			return `${year}-${month}-${day}`;
		},
		toggleSocket() {
			store.dispatch("setSetting", {
				...store.state.setting,
				socket: !store.state.setting.socket,
			})
		},
		toggleDate() {
			store.dispatch("setSetting", {
				...store.state.setting,
				createdAt: !store.state.setting.createdAt,
			})
			window.location.reload()
		},
		generateP(len) {
			let pass = ""
			const str =
				"ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789"

			for (let i = 1; i <= len; i++) {
				let char = Math.floor(Math.random() * str.length + 1)
				pass += str.charAt(char)
			}
			return pass
		},
		windowClose() {
			window.close()
		},
		getIstanbulDate(val, type = true, onlyDate = false) {
			const date = formatTz(
				addHours(type ? startOfDay(val) : endOfDay(val), -3),
				"yyyy-MM-dd HH:mm:ss",
				{ timeZone: "Europe/Istanbul" }
			)
			const tmpDate = date.split(" ")
			if (onlyDate) {
				return tmpDate[0]
			} else {
				return tmpDate[0] + "T" + tmpDate[1] + ".000Z"
			}
		},
		dateFormat(date, view) {
			return formatTz(utcToZonedTime(date, "Europe/Istanbul"), view)
		},
		openModal(data) {
			this.$modal.show(
				data.component,
				{ ...data.props },
				{ ...data.styles, shiftY: 0.1 },
				{
					"before-close": () => {
						data.close ? data.close() : () => {}
					},
					"before-open": () => {
						data.open ? data.open() : () => {}
					},
				}
			)
		},
		hasPermission(val) {
			return (
				this.$store.state.user.permissions.filter(
					(item) => item.name === val
				).length > 0
			)
		},
		numberFormat(number) {
			return new Intl.NumberFormat().format(number)
		},
		socketConnect() {
			socketConnect()
		},
		validateTRIBAN(iban) {
			// Türkiye için IBAN uzunluğu 26'dır ve "TR" ile başlar
			if (!iban.match(/^TR\d{24}$/)) {
				return false
			}

			// IBAN kontrol değeri hesaplaması için:
			// Ülke kodunu ve kontrol rakamlarını IBAN'ın sonuna taşı
			let rearrangedIban = iban.slice(4) + iban.slice(0, 4)

			// Harfleri sayılara dönüştür
			let numberIban = rearrangedIban
				.split("")
				.map((char) => {
					if (isNaN(char)) {
						return (char.charCodeAt(0) - 55).toString() // A=10, B=11, ...
					} else {
						return char
					}
				})
				.join("")

			// Büyük sayıları işlemek için mod 97-10 algoritmasını kullan
			let remainder = BigInt(numberIban) % BigInt(97)
			return remainder === BigInt(1)
		},
	},
})
Vue.config.productionTip = true
router.afterEach((to) => {
	store.commit("asideMobileStateToggle", false)
	socketConnect()
	if (to.meta && to.meta.title) {
		document.title = `${i18n.t(to.meta.title)} — ${defaultDocumentTitle}`
	} else {
		document.title = defaultDocumentTitle
	}
})


new Vue({
	router,
	store,
	i18n,
	render: (h) => h(App),
}).$mount("#app")
