import { http } from "@/utils/axios";

const SERVICE = "transaction/";

export default {
  list(params = {}) {
    return http.get(SERVICE, { params });
  },
  depositBankTransfer(params = {}) {
    return http.get(SERVICE+"bank-transfer-deposit", { params });
  },
  withdraw(params = {}) {
    return http.get(SERVICE + "withdraw", { params });
  },
  withdrawBankTransfer(params = {}) {
    return http.get(SERVICE + "bank-transfer-withdraw", { params });
  },
  remove(id) {
    return http.delete(SERVICE + id);
  },
  createWithdraw(params = {}) {
    return http.post(SERVICE + "withdraw", params);
  },
  createDeposit(params = {}) {
    return http.post(SERVICE + "deposit", params);
  },
  update(params = {}) {
    return http.put(SERVICE + params.uuid, params);
  },
  edit(id) {
    return http.get(SERVICE + id);
  },
  statusChange(params = {}) {
    return http.put(SERVICE + "status-change/" + params.uuid, params);
  },
  statusChangeWithdraw(params = {}) {
    return http.put(SERVICE + "withdraw/status-change/" + params.uuid, params);
  },
  withdrawGroupChange(params = {}) {
    return http.put(SERVICE + "withdraw/group-change/" + params.uuid, params);
  },
  check(id) {
    return http.get("check/" + SERVICE + id);
  },
  pay(params = {}) {
    return http.post("papara/pay", params);
  },
  tetherPay(params = {}) {
    return http.post("tether/pay", params);
  },
  cancel(params = {}) {
    return http.post("cancel/transaction", params);
  },
  bankTransferPay(params = {}) {
    return http.post("bank-transfer/pay", params);
  },
  iqMoneyPay(params = {}) {
    return http.post("iq-money/pay", params);
  },
  exportExcel(params = {}) {
    return http.post(SERVICE + "export/excel", params);
  },
  exportWithdrawExcel(params = {}) {
    return http.post(SERVICE + "export/withdraw/excel", params);
  },
  control(params = {}) {
    return http.post(SERVICE + "param/control", params);
  },
  paybullControl(params = {}) {
    return http.post(SERVICE + "paybull/control", params);
  },
  universalControl(params = {}) {
    return http.post(SERVICE + "universal/control", params);
  },
  newPayTrControl(params = {}) {
    return http.post(SERVICE + "newpaytr/control", params);
  },
  paypoleControl(params = {}) {
    return http.post(SERVICE + "paypole/control", params);
  },
  intControl(params = {}) {
    return http.post(SERVICE + "int/control", params);
  },
  hashCreate(params = {}, secret) {
    return http.post("hash-create?secret="+secret, params);
  },
};
